import vue from 'vue';
export class ModelService{
    static baseUri = "https://api.geteasy.one";

    static async get(path){
        try{
            let res = await fetch(`${ModelService.baseUri}${path}`,{
                headers: ModelService._setHeaders()
            });
            res = await res.json();
            return res;
        }catch (e) {
          return {
              ok:false,
              message:"Qualcosa è andato storto"
          }
        }
    }

    static async post(path, body){
        body = ModelService.asFormData(body);
        try{
            let res = await fetch(`${ModelService.baseUri}${path}`,{
                method: 'POST',
                headers: ModelService._setHeaders(),
                body
            });
            res = await res.json();
            return res;
        }catch (e) {
            return {
                ok:false,
                message:"Qualcosa è andato storto"
            }
        }

    }

    static async put(path,body){
        body = ModelService.asFormData(body);
        try{
            let res = await fetch(`${ModelService.baseUri}${path}`,{
                headers: ModelService._setHeaders(),
                method: 'PUT',
                body
            });
            res = await res.json();
            return res;
        }catch (e) {
            return {
                ok:false,
                message:"Qualcosa è andato storto"
            }
        }

    }

    static async delete(path){
        try{
            let res = await fetch(`${ModelService.baseUri}${path}`,{
                headers: ModelService._setHeaders(),
                method: 'DELETE'
            });
            res = await res.json();
            return res;
        }catch (e) {
            return {
                ok:false,
                message:"Qualcosa è andato storto"
            }
        }

    }

    static _setHeaders(){
        if(vue.$cookies.isKey('auth')){
            return {
                'Authorization': `Bearer ${vue.$cookies.get('auth')}`
            }
        }
        return {};
    }

    static asFormData(json) {
        const formData = new FormData();
        const keys = Object.keys(json);
        keys.forEach(el => {
            formData.append(el, json[el]);
        })
        return formData;
    }
}