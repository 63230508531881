import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      firstname: '',
      lastname: '',
      email: '',
      is_email_verified: false,
      is_phone_verified: false
    }
  },
  getters: {
  },
  mutations: {
    setUser(state,user){
      state.user = user;
    },
    unsetUser(state){
      state.user = {
        firstname: '',
        lastname: '',
        email: '',
        is_email_verified: false,
        is_phone_verified: false
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
