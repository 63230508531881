<template>
  <div id="app">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"></loading>
    <notifications group="app"/>
    <div v-if="$store.state.user.firstname !== ''">
      <div class="hk-wrapper" data-layout="vertical"
           data-menu="light" data-footer="simple"
           data-hover="active" :data-layout-style="menuState">
        <nav v-if="$store.state.user.firstname !== ''" class="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
          <div class="container-fluid">
            <div class="nav-start-wrap">
              <button @click="menuState = 'collapsed'"
                      class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle d-xl-none"><span
                  class="icon"><span class="feather-icon"><align-left-icon></align-left-icon></span></span></button>
            </div>
            <div class="nav-end-wrap">
              <ul class="navbar-nav flex-row">
                <li class="nav-item">
                  <a href="/messages" class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover"><span
                      class="icon"><span
                      class=" position-relative"><span class="feather-icon"><inbox-icon></inbox-icon></span><span
                      class="badge badge-sm badge-soft-primary badge-sm badge-pill position-top-end-overflow-1">0</span></span></span></a>
                </li>
                <li class="nav-item">
                  <div class="dropdown dropdown-notifications">
                    <a href="#"
                       class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover dropdown-toggle no-caret"
                       data-bs-toggle="dropdown" data-dropdown-animation role="button" aria-haspopup="true"
                       aria-expanded="false"><span class="icon"><span class="position-relative"><span
                        class="feather-icon"><bell-icon></bell-icon></span><span
                        class="badge badge-success badge-indicator position-top-end-overflow-1"></span></span></span></a>
                    <div class="dropdown-menu dropdown-menu-end p-0">
                      <h6 class="dropdown-header px-4 fs-6">Notifications<a href="#"
                                                                            class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"><span
                          class="icon"><span class="feather-icon"><settings-icon></settings-icon></span></span></a>
                      </h6>
                      <div data-simplebar class="dropdown-body  p-2">
                        <a href="javascript:void(0);" class="dropdown-item">
                          <div class="media">
                            <div class="media-head">
                              <div class="avatar avatar-rounded avatar-xs">
                                <img src="dist/img/avatar2.jpg" alt="user" class="avatar-img">
                              </div>
                            </div>
                            <div class="media-body">
                              <div>
                                <div class="notifications-text">Morgan Freeman accepted your invitation to join the team
                                </div>
                                <div class="notifications-info">
                                  <span class="badge badge-soft-success">Collaboration</span>
                                  <div class="notifications-time">Today, 10:14 PM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          <div class="media">
                            <div class="media-head">
                              <div class="avatar  avatar-icon avatar-xs avatar-success avatar-rounded">
													<span class="initial-wrap">
														<span class="feather-icon"><i data-feather="inbox"></i></span>
													</span>
                              </div>
                            </div>
                            <div class="media-body">
                              <div>
                                <div class="notifications-text">New message received from Alan Rickman</div>
                                <div class="notifications-info">
                                  <div class="notifications-time">Today, 7:51 AM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          <div class="media">
                            <div class="media-head">
                              <div class="avatar  avatar-icon avatar-xs avatar-pink avatar-rounded">
													<span class="initial-wrap">
														<span class="feather-icon"><i data-feather="clock"></i></span>
													</span>
                              </div>
                            </div>
                            <div class="media-body">
                              <div>
                                <div class="notifications-text">You have a follow up with Jampack Head on Friday, Dec 19
                                  at 9:30 am
                                </div>
                                <div class="notifications-info">
                                  <div class="notifications-time">Yesterday, 9:25 PM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          <div class="media">
                            <div class="media-head">
                              <div class="avatar avatar-xs avatar-rounded">
                                <img src="dist/img/avatar3.jpg" alt="user" class="avatar-img">
                              </div>
                            </div>
                            <div class="media-body">
                              <div>
                                <div class="notifications-text">Application of Sarah Williams is waiting for your
                                  approval
                                </div>
                                <div class="notifications-info">
                                  <div class="notifications-time">Today 10:14 PM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          <div class="media">
                            <div class="media-head">
                              <div class="avatar avatar-xs avatar-rounded">
                                <img src="dist/img/avatar10.jpg" alt="user" class="avatar-img">
                              </div>
                            </div>
                            <div class="media-body">
                              <div>
                                <div class="notifications-text">Winston Churchil shared a document with you</div>
                                <div class="notifications-info">
                                  <span class="badge badge-soft-violet">File Manager</span>
                                  <div class="notifications-time">2 Oct, 2021</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          <div class="media">
                            <div class="media-head">
                              <div class="avatar  avatar-icon avatar-xs avatar-danger avatar-rounded">
													<span class="initial-wrap">
														<span class="feather-icon"><i data-feather="calendar"></i></span>
													</span>
                              </div>
                            </div>
                            <div class="media-body">
                              <div>
                                <div class="notifications-text">Last 2 days left for the project to be completed</div>
                                <div class="notifications-info">
                                  <span class="badge badge-soft-orange">Updates</span>
                                  <div class="notifications-time">14 Sep, 2021</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="dropdown-footer"><a href="#"><u>View all notifications</u></a></div>
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <div class="dropdown ps-2">
                    <a class=" dropdown-toggle no-caret" href="#" role="button" data-bs-display="static"
                       data-bs-toggle="dropdown" data-dropdown-animation data-bs-auto-close="outside"
                       aria-expanded="false">
                      <div class="avatar avatar-primary avatar-xs avatar-rounded">
                        <span class="initial-wrap">{{ $store.state.user.firstname.substr(0, 1) }}</span>
                      </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <div class="p-2">
                        <div class="media">
                          <div class="media-head me-2">
                            <div class="avatar avatar-primary avatar-xs avatar-rounded">
                              <span class="initial-wrap">{{ $store.state.user.firstname.substr(0, 1) }}</span>
                            </div>
                          </div>
                          <div class="media-body">
                            <a href="#" class="d-block link-dark fw-medium">{{ $store.state.user.firstname }}</a>
                            <div class="fs-7">{{ $store.state.user.email }}</div>
                            <a @click="onLogout" href="#" class="d-block fs-8 link-secondary"><u>Logout</u></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div v-if="$store.state.user.firstname !== ''" class="hk-menu">
          <div class="menu-header">
          <span>
            <router-link class="navbar-brand" to="/">
              <img height="50" width="50" class="brand-img img-fluid" src="@/assets/logo.svg" alt="brand"/>

              <span class="brand-img img-fluid">Get Easy</span>
            </router-link>
          </span>
          </div>
          <div data-simplebar class="nicescroll-bar">
            <div class="menu-content-wrap">
              <div class="menu-group">
                <ul class="navbar-nav flex-column">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/">
                    <span class="nav-icon-wrap">
                      <span class="svg-icon">
                        <grid-icon></grid-icon>
                      </span>
                    </span>
                      <span class="nav-link-text">Dashboard</span>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="menu-group">
                <ul class="navbar-nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="/">
                    <span class="nav-icon-wrap">
                      <span class="svg-icon">
                        <codesandbox-icon></codesandbox-icon>
                      </span>
                    </span>
                      <span class="nav-link-text">Servizi</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="menu-group">
                <ul class="navbar-nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="/">
                    <span class="nav-icon-wrap">
                      <span class="svg-icon">
                        <credit-card-icon></credit-card-icon>
                      </span>
                    </span>
                      <span class="nav-link-text">Pagamenti</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="menu-group">
                <ul class="navbar-nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="/">
                    <span class="nav-icon-wrap">
                      <span class="svg-icon">
                       <zap-icon></zap-icon>
                      </span>
                    </span>
                      <span class="nav-link-text">Easy Store</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div @mouseover="menuState = 'default'" id="hk_menu_backdrop" class="hk-menu-backdrop"></div>
        <div class="hk-pg-wrapper pb-0">
          <div class="hk-pg-body py-0">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import {ModelService} from "@/core/ModelService";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {nextTick} from "vue";
import {
  AlignLeftIcon,
  InboxIcon,
  BellIcon,
  SettingsIcon,
  GridIcon,
  CodesandboxIcon,
  CreditCardIcon,
  ZapIcon
} from "vue-feather-icons";
import Vue from "vue";

export default {

  name: 'app-default',
  data() {
    return {
      isLoading: false,
      menuState: 'collapsed'
    }
  },
  async mounted() {
    nextTick(() => {
      Vue.prototype.$showLoader = this.showLoader;
    });
    this.isLoading = true;
    let res = await ModelService.get('/user/me');
    if (res.ok) {
      this.$store.commit('setUser', res.user);
    }
    this.isLoading = false;
  },
  components: {
    Loading,
    AlignLeftIcon,
    InboxIcon,
    BellIcon,
    SettingsIcon,
    GridIcon,
    CodesandboxIcon,
    CreditCardIcon,
    ZapIcon
  },
  methods: {
    showLoader(bool) {
      this.isLoading = bool;
    },
    onLogout() {
      this.$cookies.remove('auth');
      this.$store.commit('unsetUser');
      this.$router.push({path: '/login'});
    }
  }
}
</script>